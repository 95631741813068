.interactivecard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* max-width: 75%; */
    margin-top: 5px;
    padding: 5px;
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    height: fit-content;
    background: #0000B2;
    cursor: pointer;
    color: #FFF;
}

.interactiveheader{
    cursor: pointer;
    border: solid 1px #f2f2f2;
    padding: 5px;
    background-color: #3ebada;
    color: #FFF;
    font-family: verdana;
}

.interactivecontent {
    margin-top: 3px;
    border-left: solid 1px #f2f2f2;
    border-right: solid 1px #f2f2f2;
    border-bottom: solid 1px #f2f2f2;
    border-radius: 0 0 5px 5px;
    padding: 0;
    margin: 0;
    font-family: verdana;
    font-size: small;
    background-color: #82cde0;
}

.flex-radio {
    display: flex;
    justify-content: space-between;
    margin: auto;
}

.flex-dropdwn {
    display: flex;
    justify-content: space-evenly;
    margin: auto;
    align-items: center;
}

.interactiveinput {
    -webkit-appearance: none;
    -moz-appearance: textfield;
    /* margin: 0; */
    width: 32px;
    height: 20px;
    text-align: left;
    border: 1px solid black;
    margin-right: 5px;
}

.rc-slider-mark {
    font-size: xx-small!important;
}