.row-height-performance {
    height: 100% !important;
}

.main-container-performance {
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #3ebada;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    padding: 5px;
}

.sub-container-performance {
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #4974a5;
    justify-content: center;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 5px;
    color: white;
    height: fit-content;
}

/* .sub-container-div-performance {
    display: flex;
    margin-left: auto !important;
    margin-right: auto !important;
    align-items: center !important;
    justify-content: center !important;
} */

.performance-table-performance {
    word-wrap: break-word;
    table-layout: fixed;
    color: white;
    align-content: center;
    align-self: center;
    align-items: center;
    text-align: center;
}