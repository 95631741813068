.trendscard {
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    /* max-width: 75%; */
    margin-top: 5px;
    /* padding: 5px; */
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    width: 100%;
    background: #0000B2;
    cursor: pointer;
    color: #FFF;
}

.trendsheight {
    height: fit-content;
}

.trendsscroll {
    overflow-x: auto;
}

.trendsheader{
    cursor: pointer;
    border: solid 1px #f2f2f2;
    padding: 5px;
    background-color: #3ebada;
    color: #FFF;
    font-family: verdana;
}

.trendscontent{
    border-left: solid 1px #f2f2f2;
    border-right: solid 1px #f2f2f2;
    border-bottom: solid 1px #f2f2f2;
    border-radius: 5px;
    padding: 0;
    margin: 0;
    font-family: verdana;
    font-size: small;
    background-color: #f2f2f2;
    /* overflow-x: auto; */
}

.trendstr {
    padding: 3px;
}

.select-color {
    color: black;
}

.popover-scroll {
    overflow-y: auto;
    height: 500px;
}

.select-dimensions {
    width: 75% !important;
    height: 20px !important;
}