.main-container {
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: rgba(16, 5, 174, 0.853)!important;
    color: white!important;
    justify-content: center;
    align-items: center;
    margin-top: 5px;
    padding: 5px;
}

.sub-container {
    display: block;
    box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
    border-radius: 5px;
    font-family: Arial, Helvetica, sans-serif;
    background-color: #4974a5;
    justify-content: center;
    align-items: center;
    margin-top: 10px;
    padding: 5px;
    color: white;
}

.sub-container-div {
    display: flex;
    margin-left: auto !important;
    margin-right: auto !important;
    align-items: center !important;
    justify-content: center !important;
}

.sub-container-select {
    color: black;
}

.main-container-select {
    color: black;
}